.filterLabel {
  position: absolute;
  top: -12px;
  left: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.23);
  background-color: #FFFFFF;
  height: 12px;
  padding: 0 3px;
}

.dateRangeWrapper {
  position: relative;
  display: grid;
  grid-template: 16px 16px/auto 1fr;
  align-content: center;
  row-gap: 0;
  column-gap: 5px;
  height: 40px;
  width: 250px;
  padding: 2px 2px 2px 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;

  &:focus-within {
    border: 2px solid #333382;

    .filterLabel {
      color: #333382;
    }
  }
}

.dateRangeInputWrapper {
  padding: 0;
  width: 100%;
  height: 100%;
}

.dateRangeInput {
  border: 0;
  outline: none;
  height: 16px;
  color: rgba(0, 0, 0, 0.23);
  font-family: Roboto, sans-serif;
}

.dateRangeInputActive {
  color: #333382;
}

.dateRangeInputLabel {
  font-size: 12px;
  justify-self: flex-end;
  color: rgba(0, 0, 0, 0.23);
}

.removeFilterButton {
  position: absolute;
  top: 0;
  right: 0;
}

.applyFilterButton {
  grid-column: 3;
  grid-row: 1 / span 2;
}