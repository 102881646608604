@mixin header-height{
  height: 64px;
}

@mixin mui-table-container{
  position: relative;
  overflow:hidden;
}


@mixin main-container {
  height: 100%;
  background-color: #FFFFFF;
  position: relative;
  display: grid;
  grid-template: auto 1fr/auto;
  grid-auto-flow: row;
}


@mixin summary-main {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template: auto 1fr / 1fr;
  row-gap: 10px;
  padding: 16px;
}
@mixin epcis-message-summary-main {
  display: grid;
  grid-template: auto / 1fr;
  grid-auto-flow: row;
  row-gap: 32px;
  padding: 16px;
}
@mixin event-summary-main {
  display: grid;
  overflow: auto;
  grid-template: auto / 1fr 1fr;
  align-content: start;
  grid-gap: 16px;
  padding: 16px;
  background-color: #FFFFFF;
}
@mixin event-set-summary-main {
  position: relative;
  width: 100%;
  height: 100%;
}
@mixin message-summary-main {
  display: grid;
  overflow: auto;
  align-content: start;
  grid-template: auto / 1fr 1fr;
  padding: 16px;
  grid-gap: 16px;
  background-color: #FFFFFF;
}


@mixin summary-main-row {
  display: grid;
  grid-template: auto / 1fr 1fr;
  grid-gap: 16px;
}

@mixin event-set-button-container {
  position: absolute;
  top: 0;
  right: 0;
  background: #FFFFFF;
  padding: 20px;
}

@mixin summary-button-container {
  display: grid;
  justify-self: flex-end;
  grid-auto-flow: column;
  column-gap: 10px;
}

@mixin button-form-container {
  display: grid;
  grid-template: 1fr / 1fr auto;
  grid-auto-flow: column;
  column-gap: 16px;
  justify-items: flex-end;
  padding: 24px;
}

@mixin button-container {
  margin-left: 5px;
}


@mixin update-form-container {
  height: 100%;
  display: grid;
  grid-template: auto / 1fr 1fr;
  align-content: start;
  grid-gap: 16px;
  background-color: #FFFFFF;
  overflow: auto;
  padding-top: 6px;
  padding-right: 10px;
}


@mixin toolbar-container {
  display: flex;
  background-color: #FFFFFF;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 11px;
}


@mixin list-header {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 20px;
}

@mixin list-button-container {
  background-color: #FFFFFF;
  min-width: 300px;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  column-gap: 10px;
}


@mixin message-map-container {
  height: 100%;
  display: grid;
  grid-template: auto 1fr / 1fr;
  background-color: #FFFFFF;
}















