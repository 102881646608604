@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}

.mainContainer{
  @include main-container;
}

.messageMapContainer{
  @include message-map-container;
}

.messageSummaryMain{
  @include message-summary-main;
}

.toolbarContainer{
  @include toolbar-container;
  margin-top: 0;
  padding-top: 20px;
}

