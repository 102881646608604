@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}
.mainContainer{
  @include main-container;
}

.summaryMain{
  @include summary-main;
}

.summaryButtonContainer{
  @include summary-button-container;
}

.formContainer{
  @include update-form-container;
}

.assignmentListToolbar{
  @include toolbar-container;
  justify-content: flex-end;
  margin-left: 11px;
}

.toolbarContainer{
  @include toolbar-container;
}

.buttonContainer{
  @include list-button-container;
}
