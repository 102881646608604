.buttonWrapper{
  position: relative;
  height: 40px;
}

.base {
  height: 40px;

  display: grid;
  grid-auto-flow: column;
  column-gap: 5px;
  align-items: center;
  padding: 0 16px;

  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;

  border-radius: 8px;
  border: none;
}

.buttonContent {
  display: flex;
  flex-grow: 1;
  align-content: center;
}

.disabled,
.loading {
  background-color: #a0a0a0;
  cursor: default;
  pointer-events: none;
}

.loading {
  & .buttonContent {
    filter: blur(2px);
  }
}

.active {
  background-color: #00c3a0;
  cursor: pointer;
}

.withIcon {
  padding-left: 10px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25px;
  stroke: #FFFFFF;
  stroke-width: 5px;
  fill: #FFFFFF;
}