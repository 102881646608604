// ####################
// ####################

// Needs refactoring

// ####################
// ####################

html, 
#page-top, 
#root, 
#app-wrapper {
  height: 100%;
}

#app-wrapper {
  display: flex;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.alert {
  margin: 0 !important;
}

.hidden {
  visibility: hidden;
}

// .MuiContainer-root {
//   max-width: unset !important;
// }

.Toastify__toast-container--top-right {
  top: 5rem !important;
}

#root {

  .api-menu {
    margin-left: 1rem;
  }
  
  //========Sidebar=============
  #sidebar-wrapper {
    height: 100%;
  }

  .app-bar-open {
    z-index: 1201;
    width: 100%;
    transition: width 0.05s cubic-bezier(0.4, 0, 0.6, 1);
  }

  .app-bar-closed {
    width: calc(100% - 240px);
    z-index: 1201;
    margin-left: 0px;
    transition: width 0.25s cubic-bezier(0.4, 0, 0.6, 1);
  }

  .app-bar-menu-btn {
    margin-right: 0px,
  }

  .app-bar-menu-btn-open {
    display: none;
  }

  .MuiDrawer-paper {
    width: 240px;
    background-color: #FFFFFF !important;
    color: white;
  }

  .drawer {
    .MuiListItem-button {
      height: 55px;
    }
  }

  .drawer-open {
    transform: none;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    width: 240px;
    flex-shrink: 0;
    white-space: 'nowrap';
  }

  .drawer-closed {
    transition: width 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    width: 74px;
    overflow-x: hidden;

    > div {
      visibility: visible !important;
      transform: translateX(0) !important;
      width: 73px !important;
    }
  }

  .toolbar {
    display: flex;
    height: 64px;
    //box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    align-items: center;
    background-color: #333382;
  }

  .grow {
    flex-grow: 1;
  }

  .section-desktop {
    display: flex;
  }

  .sidebar {
    width: 14rem;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #333382 !important;
    list-style: none;
    margin-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    color: white;
  }

#sidebar-content{
  width: 100%;
  height: calc(100% - 109px);
}
  
.sidebar-divider {
    visibility: hidden;
}

.sidebar-brand {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
  color: white;
  width: 100%;

.sidebar-brand-icon i {
  font-size: 2rem;        
}

.sidebar-brand-text {
  display: none;
}
}

.sidebar-item {
  min-height: 50px;
  color: #8288a0 !important;
  .sidebar-item-text {
      color: #d5d7df;
  }
  &.selected {
      color: #EEF3FA !important;
      .sidebar-item-text {
          color: $white !important;
      }
  }
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
}

.sidebar-item-icon {
  margin-right: 8px;
  color: inherit;
}

.sidebar-item-text {
  width: 100%;
  margin-left: 1rem;
}

.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
}

.sidebar-item-expand-arrow-expanded {
  font-weight: bold;
}

.MuiListItem-button{
    transition: none !important;
    color: #333382
}
.selected{
  background-color: #EEF3FA;
}

  #page-content {
    position: relative;
    z-index: 1; // Put above footer
    width: 100%;
    margin-top: 64px;
    height: calc(100% - 64px);
    overflow-y: auto;
  }
    
  #metrics-page {
    margin: 0 1.5rem;
  }

  #loader {
    position: absolute;
    left: '50%';
  }
}

.MuiToolbar-gutters {
  padding-left: 16px;
}

//======== Modal Dialogs ============

.MuiDialogContent-root {
  padding-top: 8px!important;
  #log-info{
    .key-value-wrapper{
      display: flex;
      border-top: 1px solid $blueberry-skyr;
      height: 42px;
      font-size: 1rem;
      align-items: center;
      padding-left: 24px;

      .key{
        width: 150px;
        font-weight: 500;
      }
    }
  }
}

.MuiDialogActions-root {
  padding: 0 24px 20px!important ;
  display: grid;
  grid-template: 1fr / 1fr auto;
  column-gap: 10px;
}

#account-edit-form-dialog{
  .MuiCardContent-root{
    padding-top: 0;
    .MuiFormControl-root{
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
  
}

.dialog-title {
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
  padding: 0 10px;
}