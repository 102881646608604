@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}

.mainContainer{
  @include main-container;
}

.summaryMain{
  @include summary-main;
}

.summaryButtonContainer {
  @include summary-button-container
}

.summaryMainRow{
  @include summary-main-row;
}

.toolbarContainer{
  @include toolbar-container;
}

.formContainer{
  @include update-form-container;
}