@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.summaryContainer{
  @include main-container;
}
.summaryMain {
  @include summary-main;
}
.summaryButtonContainer {
  @include summary-button-container;
}

.deliveryNoteListHeader {
  @include list-header;
  padding-left: 16px;
}

.deliveryNoteListButtonContainer {
  @include list-button-container;
}

.updateFormContainer {
  @include update-form-container;
}

.deliveriesToolbarContainer{
  @include toolbar-container;
  justify-content: flex-end;
  margin-left: 0;
}

.muiTableContainer{
  @include mui-table-container;
}

.divider {
  grid-column: 1 / span 2;
}
