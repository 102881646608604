@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}

.mainContainer{
  @include main-container;
  grid-template: auto auto 1fr/auto;
}
.summaryMain{
  @include summary-main;
}

.summaryButtonContainer{
  @include summary-button-container;
}

.productListHeader{
  @include list-header;
}

.productListButtonContainer{
  @include list-button-container;
}

.productTradeItemListButtonContainer{
  @include list-button-container;
  padding-top: 20px;
  padding-right: 20px;
}

.formContainer{
  @include update-form-container;
}
