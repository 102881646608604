@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}

.mainContainer{
  @include main-container;
}

.summaryMain{
  @include summary-main;
}

.summaryButtonContainer{
  @include summary-button-container;
}

.businessPartnerListHeader{
  @include list-header;
}

.businessPartnerListButtonContainer{
  @include list-button-container;
}

.formContainer{
  @include update-form-container;
}




