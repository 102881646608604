@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}

.summaryMain {
  @include summary-main;
}

.summaryButtonContainer {
  @include summary-button-container;
}

.mainContainer {
  @include main-container;
}

.formContainer {
  @include update-form-container;
}

.locationListHeader {
  @include list-header;
}

.locationListButtonContainer {
  @include list-button-container;
}

.mapContainer {
  height: 200vh;
  box-shadow: none;
}
