@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}

.mainContainer{
  @include main-container;
}

.summaryMain{
  @include event-summary-main;
}

.buttonContainer{
  @include event-set-button-container;
  padding-top: 5px;
}

.buttonFormContainer{
  @include button-form-container;
}

.toolbarContainer{
  @include toolbar-container;
}
