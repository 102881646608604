@import "../../../assets/css/partials/modules/generalСlasses.scss";

.sideBarContainer{
  height: 100vh;
}



.appBarContainer{
  z-index: 1000;
}
.appBarIconContainer{
  display: flex;
  align-items: center;
  width: 240px;
}

.appBarIconContainerClose{
  display: flex;
  align-items: center;
  width:auto;

}

.appBarIconLinkText{
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem 1rem 1rem 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  white-space: pre-wrap;
  z-index: 1;
  color: white;
  width: 100%;
  cursor: pointer;
  @media screen and (max-width: 600px){
    font-size: 0.7rem;
  }
}
.grow{
  flex-grow: 1;
}
.flexContainer{
  display: flex;
}
.userMenuContainer{
  display: flex;
  padding-right: 24px;
}



.drawerContainerOpen{
  transform: none;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  width: 240px;
  flex-shrink: 0;
  white-space: nowrap;
}
.drawerHeaderContainer{
  @include header-height;
  display: flex;
  align-items: center;
  background-color: #333382;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}
.appBarContainer{
  @include header-height;
}
.versionContainer{
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  justify-content: flex-end;

  color: rgb(133,133,133);
  font-size: 14px;
  padding: 5px 20px;
}


.sidebarItemExpandArrow{
  font-size: 1.2rem ;
}
.sidebarItemExpandArrowExpanded{
  font-size: 1.2rem ;
  font-weight: bold;
}

.sidebarItemText{
  width: 100%;
  margin-left: 1rem;
}

.sidebarItemContent{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
}
.sidebarItemIcon {
  margin-right: 8px;
  color: inherit;
}
.sidebarLinkItem{
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #333382;
}
