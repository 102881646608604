@import "../../../../assets/css/partials/modules/generalСlasses.scss";

.muiTableContainer{
  @include mui-table-container;
}

.mainContainer {
  @include main-container;
  grid-template: auto auto 1fr/auto;

  & * {
    box-shadow: none !important;
  }
}

.summaryMain {
  @include summary-main;
}

.summaryButtonContainer {
  @include summary-button-container;
}

.formContainer {
  @include update-form-container;
}

.assignmentListToolbar {
  @include toolbar-container;
  justify-content: flex-end;
  margin-left: 11px;
}

.toolbarContainer {
  @include toolbar-container;
}

.buttonContainer {
  @include list-button-container;
  padding: 20px
}

.formContainer {
  width: 100%;
  display: block;

  padding: 20px 0;

  & * {
    box-shadow: none !important;
  }
}